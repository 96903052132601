/**************************/
/* BELOW 1920×1080 */
/**************************/
@media (max-width: 160em) {
  .logo--container {
    padding: 3rem 3.2rem;
  }

  .original {
    font-size: 5.2rem;
  }

  .spotify--album--container {
    gap: 3.2rem;
    margin-top: 2.4rem;
  }

  .spotify--album {
    height: 16rem;
  }

  .science-of-music:link,
  .science-of-music:visited {
    font-size: 2.8rem;
  }

  .science-of-music img {
    height: 6.4rem;
    width: 6.4rem;
  }

  .social--media-links {
    gap: 2.8rem;
  }

  .media--icon {
    font-size: 3.8rem;
  }

  .contact--form {
    font-size: 1.9rem;
  }

  label {
    font-size: 1.7rem;
  }

  input {
    font-size: 1.7rem;
    padding: 1rem 1.8rem;
  }

  select {
    font-size: 1.9rem;
  }

  textarea {
    font-size: 1.7rem;
    padding: 1.2rem 2.2rem;
    width: 100%;
  }

  .exit--icon {
    font-size: 2.2rem;
  }

  .section--footer {
    font-size: 1.7rem;
    padding: 1.2rem 0 2.4rem 0;
  }

  .david--text h2 {
    padding: 3.2rem 2.4rem 2.6rem 2.4rem;
    font-size: 1.75rem;
  }

  .david--text p {
    font-size: 1.85rem;
    padding: 1.2rem 3.2rem 1.2rem 2.4rem;
  }
}

/**************************/
/* BELOW 1536×864 */
/**************************/
@media (max-width: 96em) {
  .logo--container {
    padding: 2.4rem 2.8rem 2.8rem 1.8rem;
  }

  .original {
    font-size: 4rem;
  }

  .general--text--container {
    font-size: 1.8rem;
  }

  .spotify--album--container {
    gap: 2.4rem;
    margin-top: 0rem;
  }

  .spotify--album {
    height: 16rem;
  }

  .science-of-music:link,
  .science-of-music:visited {
    font-size: 2.4rem;
  }

  .science-of-music img {
    height: 5.2rem;
    width: 5.2rem;
  }

  .social--media-links {
    gap: 2.8rem;
  }

  .media--icon {
    font-size: 3rem;
  }

  .contact--form {
    font-size: 1.8rem;
  }

  label {
    font-size: 1.6rem;
  }

  input {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem;
  }

  select {
    font-size: 1.8rem;
  }

  textarea {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }

  .section--footer {
    font-size: 1.5rem;
    padding: 0rem 0 1.8rem 0;
  }

  .exit--icon {
    font-size: 2.2rem;
  }

  .david--text h2 {
    padding: 3.2rem 2.4rem 2.6rem 2.4rem;
    font-size: 1.75rem;
  }

  .david--text p {
    font-size: 1.85rem;
    padding: 1.2rem 3.2rem 1.2rem 2.4rem;
  }
}

/**************************/
/* BELOW 1366×768 */
/**************************/
@media (max-width: 85.375em) {
}

/**************************/
/* BELOW 1280×720*/
/**************************/
@media (max-width: 80em) {
  .logo--container {
    padding: 2.2rem 2.8rem 0rem 1.4rem;
  }

  .original {
    font-size: 3.6rem;
  }

  .general--text--container {
    font-size: 1.7rem;
  }

  .general--text--container p {
    padding-bottom: 1rem;
  }

  .spotify--album--container {
    gap: 2.4rem;
    margin-top: 0rem;
  }

  .contact-wrapper,
  .spotify-wrapper {
    padding: 2rem 3.6rem 1.2rem 3.6rem;
  }

  .spotify--album {
    height: 10rem;
  }

  .david--form {
    width: 75% !important;
    height: 75% !important;
  }

  .science-of-music:link,
  .science-of-music:visited {
    font-size: 2.2rem;
    padding: 0rem;
    padding-right: 2rem;
  }

  .science-of-music img {
    height: 4.8rem;
    width: 4.8rem;
  }

  .social--media-links {
    gap: 2.6rem;
  }

  .media--icon {
    font-size: 2.6rem;
  }

  .contact--form {
    font-size: 1.75rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  label {
    font-size: 1.6rem;
  }

  input {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem;
  }

  select {
    font-size: 1.8rem;
  }

  textarea {
    font-size: 1.6rem;
    padding: 1rem 2rem;
    grid-column: span 2;
  }

  .submit {
    grid-column: span 2;
  }

  .submit--booking--form-btn,
  .submit--contact--form-btn {
    font-size: 1.7rem;
  }

  .btn--icon {
    padding: 1.4rem 3.6rem;
    font-size: 1.75rem;
  }

  .section--footer {
    font-size: 1.5rem;
    padding: 0rem 0 1.8rem 0;
  }

  .david--text h2 {
    padding: 2.4rem 2.4rem 1.9rem 2.4rem;
    font-size: 1.75rem;
  }

  .david--text p {
    font-size: 1.8rem;
    padding: 1.2rem 3.2rem 1.2rem 2.4rem;
  }
}

/**************************/
/* BELOW 763×1024*/
/**************************/
@media (max-width: 68em) {
  .logo--container {
    padding: 2.2rem 2.8rem 0rem 1.4rem;
  }

  .original {
    font-size: 3.6rem;
  }

  .section--nav {
    display: none;
    height: 100%;
    width: 100%;
    background-color: #000;
    padding-top: 8.6rem;
    position: absolute;
    z-index: 2;
  }

  .nav--list {
    flex-direction: column;
    padding-left: 10%;
  }

  .nav--list li {
    width: fit-content;
  }

  .links {
    top: 40%;
  }

  .main--vid-wrapper {
    padding-top: 8.6rem;
  }

  .main--vid-wrapper::before {
    content: "";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 75%;
    background-image: url("../imgs/background.jpeg");
    background-position: center;
    background-size: cover;
    z-index: 0;
  }

  .spotify-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  .form {
    width: 96% !important;
    height: 96% !important;
    box-shadow: 0 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.04);
    background-color: #fff;
    color: #131a22;
    border-radius: 0.4rem;
    padding: 1.8rem 3.6rem 1.8rem 3.6rem;

    transform: scale(0);
    animation: expand 0.3s forwards;
  }

  .david--form {
    grid-template-columns: 1fr;
    grid-template-rows: fit-content auto;
    gap: 3%;
    overflow-x: hidden;
  }

  .film-strip--container {
    width: 99%;
    height: 20rem;
    flex-direction: row;
    overflow: scroll;
    justify-content: start;
  }

  .film-strip {
    height: 100%;
  }

  .general--text--container {
    font-size: 1.6rem;
    width: 35%;
  }

  .general--text--container p {
    padding-bottom: 1rem;
  }

  .spotify--album--container {
    gap: 2.4rem;
    margin-top: 0rem;
    grid-template-columns: 1fr;
  }

  .contact-wrapper,
  .spotify-wrapper {
    padding: 2rem 3.6rem 1.2rem 3.6rem;
  }

  .spotify--album {
    height: 10rem;
  }

  .science-of-music:link,
  .science-of-music:visited {
    font-size: 2rem;
    padding: 0rem;
    padding-right: 2rem;
  }

  .science-of-music img {
    height: 4.2rem;
    width: 4.2rem;
  }

  .social--media-links {
    gap: 2.6rem;
  }

  .media--icon {
    font-size: 2.6rem;
  }

  .contact--form {
    height: fit-content !important;
    font-size: 1.75rem;
  }

  .contact--form p {
    grid-column: span 2;
  }

  .performance--form {
    background-image: none;
    padding: 0 !important;
    display: grid;
    grid-template-rows: 1fr 1.5fr;
  }

  .performance--text {
    padding: 4.8rem 3.6rem;
    line-height: 1.6;
  }

  .performance--img {
    display: inline-block;
    width: 100%;
    background-image: url("../imgs/img6.jpeg");
    box-shadow: inset 0px 10rem 20px 0 #fff;
    background-size: cover;
    background-position: center;
  }

  .book--form {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 0rem;
    width: 100% !important;
    height: 100% !important;
    padding: 2.4rem 3.6rem;
    box-shadow: 0.4rem 0.4rem 1.8rem rgba(0, 0, 0, 0.04);
  }

  .book--btn {
    bottom: 3%;
    padding: 1.2rem 3.2rem;
  }

  label {
    font-size: 1.6rem;
  }

  input {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem;
  }

  select {
    font-size: 1.8rem;
  }

  textarea {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }

  .submit--booking--form-btn,
  .submit--contact--form-btn {
    font-size: 1.65rem;
  }

  .btn--icon {
    padding: 1.2rem 3rem;
    font-size: 1.75rem;
    border-radius: 0.8rem;
  }

  .section--footer {
    font-size: 1.4rem;
    padding: 0rem 0 1.8rem 0;
  }
}

/**************************/
/* BELOW 412×915*/
/**************************/
@media (max-width: 33.375em) {
  .logo--container {
    padding: 1.4rem 0rem 0rem 0.8rem;
  }

  .original {
    font-size: 3rem;
  }

  .logo--img {
    height: 8.4rem;
  }

  .links {
    top: 60%;
  }

  .main--vid-wrapper::before {
    content: "";
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 75%;
    width: 100%;
    height: 75%;
    background-image: url("../imgs/background.jpeg");
    background-position: center;
    background-size: cover;
    z-index: 0;
  }

  .general--text--container {
    display: none;
  }

  .spotify--album--container {
    gap: 1rem;
    margin-top: 0rem;
    width: 100% !important;
    padding: 1.8rem;
    padding-top: 3.6rem;
  }

  .exit--icon {
    top: 1%;
    right: 1%;
  }

  .contact-wrapper,
  .spotify-wrapper {
    padding: 1.4rem 2.4rem 0.8rem 2.4rem;
  }

  .david--form {
    gap: 3%;
  }
  .contact--form p {
    width: 85%;
  }

  .contact--form div {
    grid-column: span 2;
  }

  .performance--form {
    text-align: center;
    letter-spacing: 0.02rem;
    font-size: 1.8rem;
    line-height: 1.4;
  }

  .performance--text {
    padding: 3.6rem 2.4rem;
  }

  .performance--img {
    box-shadow: inset 0px 5rem 20px 0 #fff;
  }

  .book--form {
    top: 0;
  }

  .film-strip--container {
    height: 15rem;
  }

  .film-strip {
    padding: 1.6rem 1rem;
  }

  .david--text h2 {
    padding: 2.1rem 0rem;
    font-size: 1.5rem;
  }

  .david--text p {
    font-size: 1.6rem;
    padding: 0.8rem 0rem;
    line-height: 1.6;
  }

  textarea {
    padding: 0.8rem 0.8rem;
    min-height: 10rem;
  }

  .spotify-wrapper {
    padding: 1.8rem 1.2rem;
    display: grid;
  }

  .spotify--album {
    height: 10rem;
  }

  .science-of-music:link,
  .science-of-music:visited {
    font-size: 1.8rem;
    padding: 0rem;
    padding-right: 1.8rem;
  }

  .science-of-music img {
    height: 3.2rem;
    width: 3.2rem;
  }

  .social--media-links {
    gap: 2.6rem;
  }

  .media--icon {
    font-size: 2.6rem;
  }

  .contact--form {
    font-size: 1.75rem;
    gap: 2.8rem;
  }

  label {
    font-size: 1.6rem;
  }

  input {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem;
  }

  select {
    font-size: 1.8rem;
  }

  textarea {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }

  .submit--booking--form-btn,
  .submit--contact--form-btn {
    font-size: 1.65rem;
  }

  .book--btn {
    bottom: -5%;
    padding: 1.2rem 3.2rem;
  }

  .btn--icon {
    padding: 0rem 3rem;
    font-size: 1.75rem;
    border-radius: 0.8rem;
  }

  .btn--icon-som {
    padding: 0.8rem 1.2rem;
  }

  .section--footer {
    font-size: 1.4rem;
    padding: 0rem 0 1.8rem 0;
  }

  .form {
    width: 100%;
    height: 100%;
  }
}

/**************************/
/* BELOW 360×800*/
/**************************/
@media (max-width: 22.5em) {
}

/***********************************/
/* BELOW 390 width and 700 height*/
/***********************************/
@media (max-width: 24.375em), (max-height: 43.75em) {
}
